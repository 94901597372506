import Announcements from './components/Announcements/index';
import Calendar from './components/Calendar/index';
import CardGroupPanel from './components/CardGroupPanel';
import CardGroupSection3Col from './components/CardGroupSection3Col';
import ContentPageCard from './components/ContentPageCard';
import ExploreCards from './components/ExploreCards/index';
import Footer from './components/Footer/index';
import GenericHero from './components/GenericHero';
import Header from './components/Header/index';
import HomeAuthenticatedHero from './components/HomeAuthenticatedHero/index';
import HomeAuthenticatedHeroClubAndDistrictCards from './components/HomeAuthenticatedHeroClubAndDistrictCards/index';
import HomeAuthenticatedHeroInfoCards from './components/HomeAuthenticatedHeroInfoCards/index';
import L1Hero from './components/L1Hero';
import OneColumnCalloutPanel from './components/OneColumnCalloutPanel';
import PromotionCard from './components/PromotionCard';
import RotaryMedia from './components/RotaryMedia/index';
import RotaryMicrositeCard from './components/RotaryMicrositeCard';
import RotaryOrgCard from './components/RotaryOrgCard';
import RotaryToolCard from './components/RotaryToolCard';
import ThreeColumnImageCallout from './components/ThreeColumnImageCallout';
import ToolsCards from './components/ToolsCards/index';
import TwoColumnGreyWrapper from './components/TwoColumnGreyWrapper';
import TwoColumnTextCallout from './components/TwoColumnTextCallout';
import L1Sublayout from './sublayouts/L1Sublayout';
import RotaryFullWidthSublayout from './sublayouts/RotaryFullWidthSublayout';
import DetailPageSublayout from './sublayouts/DetailPageSublayout';
import ColumnLayout from './components/ColumnLayout';
import RichText from './components/RichText';

const components = new Map();

components.set('Header', Header);
components.set('Footer', Footer);
components.set('GenericHero', GenericHero);
components.set('ThreeColumnImageCallout', ThreeColumnImageCallout);
components.set('PromotionCard', PromotionCard);
components.set('OneColumnCalloutPanel', OneColumnCalloutPanel);
components.set('TwoColumnTextCallout', TwoColumnTextCallout);
components.set('RotaryFullWidthSublayout', RotaryFullWidthSublayout);
components.set('HomeAuthenticatedHero', HomeAuthenticatedHero);
components.set('ToolsCards', ToolsCards);
components.set('TwoColumnGreyWrapper', TwoColumnGreyWrapper);
components.set('Calendar', Calendar);
components.set('RotaryMedia', RotaryMedia);
components.set(
  'HomeAuthenticatedHeroClubAndDistrictCards',
  HomeAuthenticatedHeroClubAndDistrictCards
);
components.set('Announcements', Announcements);
components.set(
  'HomeAuthenticatedHeroInfoCards',
  HomeAuthenticatedHeroInfoCards
);
components.set('ExploreCards', ExploreCards);

components.set('CardGroupSection3Col', CardGroupSection3Col);
components.set('ContentPageCard', ContentPageCard);
components.set('L1Hero', L1Hero);
components.set('RotaryMicrositeCard', RotaryMicrositeCard);
components.set('RotaryOrgCard', RotaryOrgCard);
components.set('RotaryToolCard', RotaryToolCard);
components.set('L1Sublayout', L1Sublayout);
components.set('CardGroupPanel', CardGroupPanel);
components.set('DetailPageSublayout', DetailPageSublayout);
components.set('ColumnLayout', ColumnLayout);
components.set('RichText', RichText);

export default function componentFactory(componentName) {
  return components.get(componentName);
}
