import { Column, ColumnLayout as ColumnLayoutNPM } from '@rotaryintl/component-column-layout';
import { Placeholder, Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import React from 'react';
/** Dropdown values from Sitecore. */
const layout = {
  fullWidthCentered: 'full-width-centered',
  rightRail: 'right-rail',
};
const ColumnLayout = ({ rendering, fields: { Title, TitleAlignment, ColumnLayout: ColumnLayoutField } = {} }) => (
  <ColumnLayoutNPM title={<Text field={Title} />} titleAlign={TitleAlignment?.fields?.Value?.value ?? 'left'}>
    {
      {
        [layout.fullWidthCentered]: (
          <>
            <Column span="12-centered">
              <Placeholder name="columnlayout-main" rendering={rendering} />
            </Column>
          </>
        ),
        [layout.rightRail]: (
          <>
            <Column span="8">
              <Placeholder name="columnlayout-main" rendering={rendering} />
            </Column>
            <Column span="4">
              <Placeholder name="columnlayout-rail" rendering={rendering} />
            </Column>
          </>
        ),
      }[ColumnLayoutField?.fields?.Value?.value ?? layout.rightRail]
    }
  </ColumnLayoutNPM>
);
export default withSitecoreContext()(ColumnLayout);